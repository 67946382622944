/* @tailwind base; */ /* Preflight will be injected here */
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #6c757d;
  border-radius: 6px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #495057;
}
*::-webkit-scrollbar-thumb:active {
  background-color: #495057;
}

.ais-LoadMoreWithProgressBar-progressBar-bar {
  display: none;
}

html {
  scroll-behavior: smooth;
}
